import { PageProps } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import Spinner from "../../components/Spinner"
import PageWithBackgroundImage from "../../components/pageWithBackgroundImage"
import { colors } from "../../styles/globals"
import { getAllUrlParams } from "../../utils"
import { putUndoEmailSwap } from "../../utils/api/login"
const styles = {
  modalContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  loginLogoContainer: {
    width: 20,
    height: 20,
    top: 6,
    right: 24,
    position: "absolute",
    cursor: "pointer",
    // backgroundColor: "red",
  },
  loginContainer: {
    position: "absolute",
    top: "50vh",
    left: "50vw",
    width: 480,
    minHeight: 320,
    // height: 560,
    marginLeft: -260,
    marginTop: -240,
    backgroundColor: colors.loginModalBackground,
    textAlign: "center",
  },
}

export default function Home({ location, navigate }: PageProps) {
  const [infoMsg, setInfoMsg] = useState("Vă rugăm așteptați")

  useEffect(() => {
    async function requestPutUndoEmailSwap(emailSwapId, token) {
      try {
        const apiResponse = await putUndoEmailSwap({ emailSwapId, token })
        if (apiResponse.type) {
          setInfoMsg(
            "Schimbarea adresei de email a fost anulată. Contul dumneavoastră a fost blocat. Pentru a vă putea connecta trebuie să resetați parola. În cateva secunde veți fi redirecționat către formularul de resetare a parolei."
          )
          setTimeout(() => navigate("contulMeu?showResetPassword=1"), 6700)
        } else
          setInfoMsg(
            "Nu am putut anula modificarea. Linkul accesat este greșit sau modificarea a fost deja anualată."
          )
      } catch (error) {
        console.log(error)
        setInfoMsg(
          "A apărut o eroare la cerința dumneavoastră. Ori nu sunteți conectat la internet ori avem o eroare de implementare. Dacă eroarea persistă vă rugăm să contactați asistența tehnică. Vă mulțumim!"
        )
      }
    }

    const { emailSwapId, token } = getAllUrlParams(location.search)

    if (emailSwapId && token) requestPutUndoEmailSwap(emailSwapId, token)
    else setInfoMsg("Nu am putut anula modificarea. Linkul accesat este greșit")
  }, [location.search])

  return (
    <PageWithBackgroundImage location={location}>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div
        style={{
          color: "#FFF",
          textAlign: "center",
          marginTop: 300,
          fontSize: 30,
          width: "100vw",
        }}
      >
        <div style={styles.modalContainer}>
          <div style={styles.loginContainer}>
            <StaticImage
              style={{ marginTop: 32 }}
              height={120}
              loading="eager"
              layout="constrained"
              src="../images/logoNou.png"
              alt="Logo gazmir"
            />
            <div
              style={{
                padding: "20px 50px",
                // marginTop: 32,
                textAlign:
                  infoMsg === "Vă rugăm așteptați" ? "center" : "justify",
                fontSize: 18,
                color: infoMsg.includes("anulată")
                  ? colors.green
                  : infoMsg === "Vă rugăm așteptați"
                  ? colors.main
                  : colors.red,
              }}
            >
              {infoMsg}
            </div>
            <Spinner
              containerStyle={{
                width: 40,
                height: 40,
                margin: "20px auto 0px auto",
              }}
              show={infoMsg === "Vă rugăm așteptați"}
            />
          </div>
        </div>
      </div>
    </PageWithBackgroundImage>
  )
}
